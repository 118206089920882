import { initializeSegment } from '@helpers/segment_analytics_helper';

// Warning : this function is duplicated in the code
function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

function parseURLCategory(pathname) {
  switch (true) {
    case /^\/$/.test(pathname):
      return 'Homepage';
    case /^\/location-immobiliere$/.test(pathname):
      return 'FlatSearch';
    case /^\/location-immobiliere\//.test(pathname):
      return 'FlatPages';
    case /^\/user\//.test(pathname):
      return 'UserPages';
    case /^\/landlord\//.test(pathname):
      return 'LandlordPages';
    case /^\/investor\//.test(pathname):
      return 'InvestorPages';
    case /^\/contractor\//.test(pathname):
      return 'ContractorPages';
    case /^\/looker\//.test(pathname):
      return 'LookerPages';
    case /^\/inventory_agent\//.test(pathname):
      return 'InventoryAgentPages';
    case /^\/agent\//.test(pathname):
      return 'AgentPages';
    case /^\/manager\//.test(pathname):
      return 'ManagerPages';
    case /^\/admin\//.test(pathname):
      return 'AdminPages';
    default:
      return 'PublicPages';
  }
}

function toSnakeCaseString(key) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

function toSnakeCaseObject(object) {
  var newObject = {};
  for (var key in object) {
    newObject[toSnakeCaseString(key)] = object[key];

    // Setting first name and last name with first char uppercase
    if (key == 'firstName' || key == 'lastName')
      newObject[toSnakeCaseString(key)] = object[key].charAt(0).toUpperCase() + object[key].slice(1).toLowerCase();
  }
  return newObject;
}

if (getCookie('should_be_tracked') === 'true') {
  (function(){
    // Get segment instance
    const analytics = initializeSegment();

    // Parsing current URL to define page category
    const pathname = window.location.pathname;
    const category = parseURLCategory(pathname);
    const tracking_options = { mobile_app: !!window.isMobileApp };

    // Make the first page call to load the integrations. If
    // you'd like to manually name or tag the page, edit or
    // move this call however you'd like.
    analytics.page(category, pathname, tracking_options);

    // Get meta tag holding information about user
    var currentUserTag = document.querySelector('meta[name="current-user"]');
    if (currentUserTag) {
      analytics.identify(currentUserTag.content, toSnakeCaseObject(currentUserTag.dataset));
    }
  })();
}
